/* You can add global styles to this file, and also import other style files */

@charset "utf-8";
@font-face {
font-family: "HelveticaNeue";
src: url("assets/HelveticaNeue.woff") format("woff");
font-weight: lighter;
font-style: normal;
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'HelveticaNeue' !important;
}

body {
    min-height: 100vh;
    background: #fff;
    color:white;
    font-weight: bolder;
    margin-left: 352px;
}

.alkali-metal {
    background: #f47126;
}

.alkaline-earth-metal {
    background: #ffc00c;
}

.lanthanide {
    background: #3ab549;
}

.actinide {
    background: #ec1a3b;
}

.transition-metal {
    background: #06b7aa;
}

.post-transition-metal {
    background: #30bfe7;
}

.metalloid {
    background: #7f3395;
}

.nonmetal {
    background: #e42292;
}

.noble-gas {
    background: #353797;
}

.unknown-metal {
    background: #dddddd;
}
.color{
    color: white;
}
.metal-color{
    color: black;
    font-weight:bolder;
}

button{
    border: solid 0px white !important;
}